import * as React from "react";
import {
  PropertyDetailedAmenities as SharedUIPropertyDetailedAmenities,
  PropertyAmenityDescription as SharedUIPropertyAmenityDescription,
} from "@shared-ui/lodging-property-details";

import { RefTarget } from "@shared-ui/ref-context";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { PropertyAmenitiesProps } from "../types";
import { UitkSpacing } from "@egds/react-core/spacing";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { useExperiment } from "@shared-ui/experiment-context";

const WrappedPropertyDetailedAmenities = PropertyBexApiWrapper(SharedUIPropertyDetailedAmenities);
const WrappedPropertyAmenityDescription = PropertyBexApiWrapper(SharedUIPropertyAmenityDescription);

export const PropertyAmenityDescriptionLazyLoad: React.FC<PropertyAmenitiesProps> = (props: PropertyAmenitiesProps) => {
  const { context, templateComponent } = props;
  const { enableExpando, displayThreshold, showBorder } = templateComponent.config;
  const { exposure } = useExperiment("Dateless_PDP_Progressive_disclosure_Fully_collapsed_content_sections");
  const disableSectionSeparator = exposure.bucket === 1;

  const getSpacing = () => {
    if (showBorder && displayThreshold === 0) {
      return {
        small: { blockend: "two", inline: "three" },
        medium: { blockend: "two", inline: "unset" },
      };
    }
    if (showBorder) {
      return {
        small: { blockend: "six", inline: "three" },
        medium: { blockend: "six", inline: "unset" },
      };
    }
    if (disableSectionSeparator) {
      return "unset";
    }
    return { block: "three" };
  };

  return (
    <UitkSpacing margin={getSpacing()}>
      <RefTarget name="Amenities" tag="section">
        <LazyLoad context={context}>
          {context?.site?.brand === "vrbo" ? (
            <WrappedPropertyDetailedAmenities
              context={context}
              isExpandoSection={enableExpando}
              displayThreshold={displayThreshold}
            />
          ) : (
            <WrappedPropertyAmenityDescription
              context={context}
              isExpandoSection={enableExpando}
              displayThreshold={displayThreshold}
              disableSectionSeparator={disableSectionSeparator}
              showBorder={showBorder}
            />
          )}
        </LazyLoad>
      </RefTarget>
    </UitkSpacing>
  );
};

export default PropertyAmenityDescriptionLazyLoad;
